<template>
  <div class="grid sm:grid-cols-2 2xl:grid-cols-5 gap-8">
    <div
      class="relative shadow-[0_2px_8px_0]  shadow-black/10 flex items-center justify-between rounded-5 lg:rounded-10 px-5 lg:px-10 py-5.5 lg:py-8 gap-4 overflow-hidden lg:col-span-2 col-span-full"
      :class="color === 'gray' ? 'bg-#F4F8F5' : 'bg-white'"
    >
      <div class="flex flex-col gap-4 lg:gap-5 shrink-0">
        <p class="text-tekstovyy text-6 lg:text-7 font-700 max-w-62">Присоединяйте свой ресторан</p>
        <p class="text-seryy text-4.05 leading-1.2 max-lg:max-w-43 max-w-62">Привлекайте новых посетителей и расширяйте зону продажи</p>
        <Button
          class="w-fit"
          @click="openPartnerPopup"
        >
          Подключить
        </Button>
      </div>
      <img
        class="mix-blend-darken h-50 lg:h-47 max-lg:absolute max-lg:translate-x-1/3 max-lg:right-0"
        src="/imgs/join-your-restaurant1.png"
        alt=""
      >
    </div>
    <div
      class="relative shadow-[0_2px_8px_0] shadow-black/10 flex flex-col sm:flex-row lg:items-center justify-between rounded-5 lg:rounded-10 px-5 lg:w-210 lg:px-6 py-5.5 lg:py-8 gap-4 lg:col-span-3 col-span-full"
      :class="color === 'gray' ? 'bg-#F4F8F5' : 'bg-white'"
    >
      <div class="flex flex-col gap-4 lg:gap-5">
        <p class="text-tekstovyy text-6 lg:text-7 font-700 max-w-69">Проще чем заказать столик в ресторане</p>
        <p class="text-seryy text-4.05 leading-1.2 max-w-65">Еда уже почти у вас дома!Закажите в любимом ресторане</p>
        <Button
          class="w-fit"
          @click="detectDeviceAndRedirect"
        >
          Установить
          <template #suffix>
            <span class="i-custom:full-arrow opacity-60 text-3.5"></span>
          </template>
        </Button>
      </div>
      <div class="flex max-sm:order-first sm:hidden lg:flex items-end h-full lg:absolute top-0 left-32% border-b border-black/10 lg:border-none w-full lg:w-60 pt-5 lg:pt-0">
        <img
          class="lg:h-110%  mx-auto"
          src="/imgs/iphone_11_pro_mockup.png"
          alt="Мобильное приложение SakhFood"
        >
      </div>
      <div class="flex items-center gap-2.5 lg:gap-7.5 shrink-0">
        <div class="flex flex-col items-center gap-2">
          <NuxtLink
            to="https://play.google.com/store/apps/details?id=com.sakhfood"
            target="_blank"
          >
            <GooglePlay class="text-black transition-color hover:text-firmennyy" />
          </NuxtLink>
          <img
            width="163"
            height="163"
            class="size-21.5 lg:size-35"
            src="/imgs/qr_google.jpg"
          />
        </div>
        <div class="flex flex-col items-center gap-2">
          <NuxtLink
            to="https://apps.apple.com/ru/app/sakhfood/id6505093916"
            target="_blank"
          >
            <AppStore class="text-black transition-color hover:text-firmennyy" />
          </NuxtLink>
          <img
            width="163"
            height="163"
            class="size-21.5 lg:size-35"
            src="/imgs/qr-code.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePartnersPopup } from '~/composables/popups/usePartnersPopup';
defineProps({
  color: { type: String as PropType<'white' | 'gray'>, default: 'white' }
});
const { openPartnerPopup } = usePartnersPopup();

const detectDeviceAndRedirect = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isIOS = /iphone|ipad|ipod/.test(userAgent);
  const isMac = /macintosh|mac os x/.test(userAgent);
  const isAndroid = /android/.test(userAgent);

  if (isIOS || isMac) {
    window.open('https://apps.apple.com/ru/app/sakhfood/id6505093916', '_blank');
  } else if (isAndroid || !isIOS) {
    window.open('https://play.google.com/store/apps/details?id=com.sakhfood', '_blank');
  }
};

</script>
